<template>
    <div class="usage-user">
        <router-link class="usage-user__link usage-user__link_back link link_red"
            ref="backLink"
            :to="{ name: 'UsageTotal' }"
        >
            Вернуться к общему отчету об использовании
        </router-link>
        <section class="usage-user__settings">
            <h2 class="sr-only">Настройки отчета "Использование"</h2>
            <form class="usage-user__settings" @submit.prevent>
                <div class="usage-user__dateranges">
                    <daterange-picker class="usage-user__daterange"
                        v-model="reportDaterangeModel"
                        name="reportDaterange"
                        :minDate="minDate"
                        :maxDate="maxDate"
                    >
                        Период отчета:
                    </daterange-picker>
                </div>
                <div class="usage-user__selects">
                    <select class="usage-user__select"
                        v-model="methodsFilterModel"
                    >
                        <option class="usage-user__option" value="all">Все отчеты</option>
                        <option class="usage-user__option"
                            v-for="method in methods"
                            :key="method.id"
                            :value="method.id"
                        >
                            {{method.title}}
                        </option>
                    </select>
                </div>
            </form>
        </section>
        <spinner class="usage-user__spinner" v-if="status === 'loading'" />
        <error-message class="usage-user__error-message" v-else-if="status === 'error'">
            Не удалось загрузить данные отчета "Использование".
        </error-message>
        <section class="usage-user__data" v-if="status === 'success'">
            <h2 class="sr-only">Данные отчета "Использование"</h2>
            <p class="usage-total__text" v-if="reportUsersMethods.length === 0">
                Нет действий, удовлетворяющих выбранным критериям.
            </p>
            <div class="usage-user__base" v-else>
                <div class="usage-user__box box">
                    <table class="usage-user__table usage-user__table_striped usage-user__table_hover">
                        <colgroup>
                            <col class="usage-user__table-column usage-user__table-column_report">
                            <col class="usage-user__table-column usage-user__table-column_date">
                            <col class="usage-user__table-column usage-user__table-column_ip">
                        </colgroup>
                        <thead>
                            <tr class="usage-user__table-row usage-user__table-row_head">
                                <th class="usage-user__table-cell usage-user__table-cell_head usage-user__table-cell_sortable"
                                    scope="col"
                                    @click="setSorting('methodTitle')"
                                >
                                    <div class="usage-user__table-cell-content">
                                        <span class="usage-user__table-value">Отчет</span>
                                        <svg-icon class="usage-user__table-value usage-user__table-value_icon"
                                            v-if="sorting.field === 'methodTitle' && sorting.direction === 'ASC'"
                                            icon="chevron-up"
                                        />
                                        <svg-icon class="usage-user__table-value usage-user__table-value_icon"
                                            v-if="sorting.field === 'methodTitle' && sorting.direction === 'DESC'"
                                            icon="chevron-down"
                                        />
                                    </div>
                                </th>
                                <th class="usage-user__table-cell usage-user__table-cell_head" scope="col">
                                    <span class="usage-user__table-value">IP-адрес</span>
                                </th>
                                <th class="usage-user__table-cell usage-user__table-cell_head usage-user__table-cell_sortable"
                                    scope="col"
                                    @click="setSorting('date')"
                                >
                                    <div class="usage-user__table-cell-content">
                                        <span class="usage-user__table-value">Дата</span>
                                        <svg-icon class="usage-user__table-value usage-user__table-value_icon"
                                            v-if="sorting.field === 'date' && sorting.direction === 'ASC'"
                                            icon="chevron-up"
                                        />
                                        <svg-icon class="usage-user__table-value usage-user__table-value_icon"
                                            v-if="sorting.field === 'date' && sorting.direction === 'DESC'"
                                            icon="chevron-down"
                                        />
                                    </div>
                                </th>
                                <th class="usage-user__table-cell usage-user__table-cell_head" scope="col">
                                    <span class="usage-user__table-value">Период отчета</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="usage-user__table-row usage-user__table-row_body"
                                v-for="userMethod in reportUsersMethods"
                                :key="`${userMethod.methodId}-${userMethod.date_text}`"
                            >
                                <td class="usage-user__table-cell usage-user__table-cell_body">
                                    <span class="usage-user__table-value">{{userMethod.methodTitle}}</span>
                                </td>
                                <td class="usage-user__table-cell usage-user__table-cell_body">
                                    <span class="usage-user__table-value">{{userMethod.ip}}</span>
                                </td>
                                <td class="usage-user__table-cell usage-user__table-cell_body">
                                    <span class="usage-user__table-value">{{userMethod.date | datetimeText}}</span>
                                </td>
                                <td class="usage-user__table-cell usage-user__table-cell_body">
                                    <span class="usage-user__table-value">{{userMethod.data.get_params | methodDaterange}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from "vuex";
    import parseISO from "date-fns/parseISO";
    import { daterangeKey, daterangeText, datetimeText } from "@/helpers/daterange";
    import { compareStrings, compareDates } from "@/helpers/compare";
    import DaterangePicker from "@/components/DaterangePicker";

    export default{
        name: "UsageUser",
        components: {
            DaterangePicker,
        },
        props: {
            id: {
                required: true
            },
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                type: Object,
                required: true
            },
            minDate: {
                type: Date,
                required: true
            },
            maxDate: {
                type: Date,
                required: true
            },
            users: {
                type: Object,
                required: true
            },
            methods: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                status: "loading"
            };
        },
        computed: {
            ...mapState({
                title: state => state.page.title,
                methodsFilter: state => state.usage.filters.user.methods,
                sorting: state => state.usage.sortings.user,
                data: state => state.usage.data.actions
            }),
            reportDaterangeModel: {
                get() {
                    return this.reportDaterange;
                },
                set(daterange) {
                    this.setUsageReportDaterange(daterange);
                }
            },
            methodsFilterModel: {
                get() {
                    return this.methodsFilter;
                },
                set(value) {
                    this.setFilter({ filter: "methods", value });
                }
            },
            requiredDateranges() {
                return [this.reportDaterange];
            },
            requiredUsages() {
                return this.requiredDateranges.map(daterange => this.data[daterangeKey(daterange)]);
            },
            reportUsage() {
                return this.data?.[daterangeKey(this.reportDaterange)];
            },
            reportUsersMethods() {
                return this.reportUsage?.map(usageItem => {
                    let user = this.users?.byId?.[usageItem.user_id];
                    let method = this.methods?.find(method => {
                        let [url_level1, url_level2] = usageItem.url.split("/");

                        return method.url === `${url_level1}/${url_level2}`;
                    });

                    return {
                        userId: user?.id,
                        userName: user?.name,
                        methodId: method?.id,
                        methodTitle: method?.title,
                        date: parseISO(usageItem.date),
                        date_text: usageItem.date,
                        ip: usageItem.ip,
                        data: usageItem.data,
                    }
                })?.filter(
                    ({ userId, methodId }) => userId && methodId
                )?.filter(
                    ({ userId }) => userId === Number(this.id)
                )?.filter(({ methodId }) => {
                        if (this.methodsFilter === "all") {
                        return true;
                    }

                    return methodId === this.methodsFilter;
                })?.sort((userMethod1, userMethod2) => {
                    let compare = this.sorting.field === "methodTitle" ? compareStrings : compareDates;

                    return compare(
                        userMethod1?.[this.sorting.field],
                        userMethod2?.[this.sorting.field],
                        this.sorting.direction
                    );
                });
            },
        },
        methods: {
            ...mapMutations([
                "setTitle",
                "setUsageReportDaterange",
                "setUsageFilter",
                "setUsageSorting",
            ]),
            ...mapActions([
                "requestUsages"
            ]),
            setFilter(filterParams) {
                this.setUsageFilter({ ...filterParams, table: "user" });
            },
            setSorting(field) {
                this.setUsageSorting({ table: "user", field });
            },
            showReport() {
                this.status = "success";
            },
            loadUsages() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[daterangeKey(daterange)])
                );

                this.requestUsages({
                    dateranges: daterangesWithoutData,
                    type: "actions"
                }).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
        },
        filters: {
            datetimeText,
            methodDaterange({ date_start, date_end }) {
                if (date_start && date_end) {
                    return daterangeText({
                        start: parseISO(date_start),
                        end: parseISO(date_end)
                    })
                }

                return "";
            },
        },
        created() {
            if (this.id == 1) {
                this.$router.push({ name: "Error401" });
            } else {
                let userName = this.users?.byId?.[this.id]?.name;

                if (userName && !this.title.includes(userName)) {
                    this.setTitle(`${this.title} - ${userName}`);
                }

                if (this.requiredUsages.every(Boolean)) {
                    this.showReport();
                } else {
                    this.loadUsages();
                }
            }
        },
        watch: {
            requiredUsages(requiredUsages) {
                if (!requiredUsages.every(Boolean)) {
                    this.loadUsages();
                }
            }
        }
    }
</script>

<style lang="scss">
    .usage-user__link  {
        &_back {
            display: inline-block;
            margin-bottom: 5px;

            @include desktop {
                margin-bottom: 10px;
            }
        }
    }
    .usage-user__settings {
        margin-bottom: 15px;
    }
    .usage-user__dateranges {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;
        margin-bottom: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .usage-user__toggles {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .usage-user__selects {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        margin-top: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .usage-user__select {
        min-width: 240px;
        padding: 4px 8px;
        border-color: $gray-line;
        border-radius: 4px;
    }
    .usage-user__box {
        padding: 16px;
        overflow-x: auto;

        &_chart {
            padding: 16px;
        }

        & + & {
            margin-top: 15px;
        }
    }
    .usage-user__table {
        width: 100%;
    }
    .usage-user__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .usage-user__table-column {
        &_title {
            width: 25%;
        }
    }
    .usage-user__table-row {
        &_body {
            .usage-user__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .usage-user__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .usage-user__table-cell {
        font-size: 20px;
        padding: 16px 8px;

        &_head {
            white-space: nowrap;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_baseline {
            vertical-align: baseline;
        }

        &_small {
            font-size: 16px;
        }

        &_tac {
            text-align: center;
        }

        &_tar {
            text-align: right;
        }
    }
    .usage-user__table-cell-content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .usage-user__table-value {
        display: block;
        line-height: 1;

        &_icon {
            margin-left: 5px;
            width: 18px;
            height: 18px;
        }

        &_diff {
            margin-left: 8px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }
    }
</style>
